import React from "react";
import Section from "components/Section";
import Card from "components/Card";
import styles from "./styles.module.scss";
import Divider from "components/Divider";
import SectionAnimation from "components/SectionAnimation";
import GaOnClickEvent from "components/GaOnClickEvent";
import envVars from "config/envVars";

interface TimeProps {
  id?: string;
}

const Time = ({ id }: TimeProps) => {
  return (
    <>
      <Section
        title="Pushing the boundaries of creativity & technology."
        subTitle="We merge imagination and technology to build applications that trigger emotions and engage audiences."
        textPlacement="top"
        background="darkBlue"
        textAlignment="left"
        id={id}
      >
        <Card
          figure={
            <div className={styles.figure}>
              <video autoPlay playsInline muted width="100%" loop>
                <source
                  src={`${envVars.PUBLIC_URL}/animations/webDevelopment.webm`}
                  type="video/webm"
                />
                <source
                  src={`${envVars.PUBLIC_URL}/animations/webDevelopment.mov`}
                  type='video/mp4; codecs="hvc1"'
                />
              </video>
            </div>
          }
        >
          <h2 className={styles.h2}>We build premium software.</h2>

          <p className={styles.p}>
            At PixelCloud, we strive to create amazing experiences that make
            people talk and deliver strategic value with a high return on
            investment. We help visualize and articulate a digital vision that
            your customers understand and people want to be a part of. We are a
            team of digital strategists, designers, and developers who are
            passionate about building beautiful things that people love to use.
          </p>
          <Divider inset="right" />
          <p className={styles.pSmall}>
            Do you have an idea you want to share with us?{" "}
            <GaOnClickEvent action="idea_email" category="contact">
              <a
                className={styles.link}
                href="mailto:info@pixelcloud.se?subject=We%20have%20an%20idea!"
              >
                We'd love to hear it!
              </a>
            </GaOnClickEvent>
          </p>
        </Card>
      </Section>
      <SectionAnimation
        title="Productivity is our focus."
        subTitle="Our goal is to help you get your innovative products and services to market quickly and efficiently. We use the latest in automation, agile development, and DevOps technologies to streamline your development process."
        textPlacement="right"
        background="darkBlue"
        animation="cloud"
      />
    </>
  );
};

export default Time;
