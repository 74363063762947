export const validate = (values: any) => {
  const errors: any = {};
  if (!values.name || values.name.length < 2) {
    errors.name = "Name is required";
  }

  if (!values.email) {
    errors.email = "Email is required";
  } else if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address";
  }

  if (
    values.number &&
    !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
      values.number
    )
  ) {
    errors.number = "Invalid phone number";
  }

  if (!values.message) {
    errors.message = "Message is required";
  } else if (values.message.trim().length < 10) {
    const charsLeft = 10 - values.message.trim().length;
    errors.message = `Add ${charsLeft} more character${
      charsLeft > 1 ? "s" : ""
    }`;
  }

  return errors;
};

export default validate;
