import React from "react";
import SectionAnimation from "components/SectionAnimation";

interface FlexibilityProps {
  id?: string;
}

const Flexibility = ({ id }: FlexibilityProps) => {
  return (
    <SectionAnimation
      id={id}
      title="Enjoy real flexibility."
      subTitle="Would you like to work fewer days and have longer weekends? We believe that efficiency should be rewarded, so we go above and beyond to provide you with the flexibility you need to fit your work-life balance.
      "
      textPlacement="right"
      background="darkBlue"
      animation="balance"
    />
  );
};

export default Flexibility;
