import React from "react";
import Section from "components/Section";
import Card from "components/Card";
import styles from "./styles.module.scss";
import Divider from "components/Divider";
import envVars from "config/envVars";

interface PersonalDevelopmentProps {
  id?: string;
}

const PersonalDevelopment = ({ id }: PersonalDevelopmentProps) => {
  return (
    <Section
      id={id}
      title="Your personal development is our focus."
      subTitle="We believe that your workplace should bring the best out of you."
      textPlacement="top"
      background="darkBlue"
      textAlignment="left"
    >
      <Card
        figure={
          <div className={styles.video}>
            <video autoPlay playsInline muted width="100%" loop>
              <source
                src={`${envVars.PUBLIC_URL}/animations/thinkingOver.webm`}
                type="video/webm"
              />
              <source
                src={`${envVars.PUBLIC_URL}/animations/thinkingOver.mov`}
                type='video/mp4; codecs="hvc1"'
              />
            </video>
          </div>
        }
      >
        <h2 className={styles.h2}>
          A tailored journey designed to unlock your full potential.
        </h2>
        <p className={styles.p}>
          We all want to reach our potential and be our best selves, but
          sometimes it can be difficult to know where to start. If you're
          looking to make some lifestyle changes but don't know where to begin,
          our team of experts can help. We'll work with you to assess your
          current habits, create a personalized nutrition plan, and get you
          active with the help of a personal trainer. With our support, you can
          make room for a better, stronger, and more confident you.
        </p>
        <Divider inset="right" />
        <p className={styles.pSmall}>
          We believe that health is more than just nutrition and exercise, and
          we will work with you to assess your areas of improvement, regardless
          of what they may be. For example, we can help you improve your sleep,
          stress levels, energy levels, and more.
        </p>
      </Card>
    </Section>
  );
};

export default PersonalDevelopment;
