import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import Section from "components/Section";
import GaOnClickEvent from "components/GaOnClickEvent";
import Button from "components/Button";
import Modal from "components/Modal";
import ContactUsModal from "components/ContactUsModal";

interface ContactUsProps {
  id?: string;
}

const ContactUs = ({ id }: ContactUsProps) => {
  const buttonRef = useRef<any>(null);

  return (
    <Section
      id={id}
      className={styles.container}
      background="gradientGreen"
      textPlacement="bottom"
      textAlignment="center"
      animationDirection="up"
      title="Let's build amazing stuff together!"
      subTitle={
        <>
          <p>
            If you have an innovative idea, we would love for you to join us and
            bring it to life. At PixelCloud, we're constantly striving to push
            the envelope and deliver cutting-edge solutions to our clients.
          </p>

          <div ref={buttonRef} className={styles.buttonContainer}>
            <GaOnClickEvent action="home_email" category="contact">
              <Button color="green">LETS DO IT!</Button>
            </GaOnClickEvent>
          </div>
        </>
      }
    >
      <Modal ButtonRef={buttonRef}>
        <ContactUsModal />
      </Modal>
      <div className={styles.ocean}>
        <div className={styles.wave}></div>
        <div className={styles.wave}></div>
      </div>
    </Section>
  );
};

export default ContactUs;
