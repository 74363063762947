import React from "react";
// import styles from "./styles.module.scss";
import SectionAnimation from "components/SectionAnimation";

interface WebDevelopmentProps {
  id?: string;
}

const WebDevelopment = ({ id }: WebDevelopmentProps) => {
  return (
    <SectionAnimation
      id={id}
      title="Data driven development."
      subTitle="
      Driven by innovation and human behavior, our team is changing the way brands connect with audiences in a digital world. We research customer behavior and patterns to help drive more value from your existing digital presence.
      "
      textPlacement="left"
      background="darkGrey"
      animation="seo"
    />
  );
};

export default WebDevelopment;
