import { useCallback, useRef, useState } from "react";
import styles from "./styles.module.scss";
import Button from "components/Button";
// TODO: copyright
// ContactusSvg might be copyrighted by the vencdor
// https://www.freepik.com/free-vector/contact-us-concept-illustration_8690678.htm
// free for personal use | requires attribution for commercial use
import ContactusSvg from "./Contactus.svg";
import { useFormik } from "formik";
import validate from "utils/validate";
import axios from "axios";
import clsx from "clsx";
import envVars from "config/envVars";
interface ContactUsModalProps {
  onClose?: () => void;
}

const ContactUsModal = ({ onClose }: ContactUsModalProps) => {
  const [loading, setLoading] = useState(false);
  const tyRef = useRef<HTMLDivElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);

  const successfullySent = useCallback((values) => {
    // no need to make user wait for slack response since its of a lower importance
    axios.post(
      envVars.SLACK_FUNCTION_URL,
      {
        name: values.name,
      },
      {
        headers: {
          authorization: envVars.API_TOKEN,
        },
      }
    );
    if (!tyRef?.current || !formRef?.current) return;
    tyRef.current.classList.remove(styles.hidden);
    formRef.current.classList.add(styles.hidden);
  }, []);

  const submitForm = useCallback(
    (values: any) => {
      setLoading(true);
      axios
        .post(envVars.EMAIL_FUNCTION_URL ?? "", values, {
          headers: {
            authorization: envVars.API_TOKEN ?? "",
          },
        })
        .then((res) => {
          successfullySent(values);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [successfullySent]
  );

  const { handleSubmit, handleChange, handleBlur, touched, values, errors } =
    useFormik({
      initialValues: {
        name: "",
        number: "",
        email: "",
        message: "",
      },
      validate,
      onSubmit: submitForm,
    });

  return (
    <div className={styles.container}>
      <div className={styles.contactUsSvg}>
        <ContactusSvg width="100%" />
      </div>
      <div className={styles.contactUsFormContainer}>
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className={styles.contactUsForm}
        >
          <h1 className={styles.formTitle}>Contact Us</h1>

          <input
            value={values.name}
            className={styles.inputField}
            name="name"
            onChange={handleChange}
            placeholder="name *"
            onBlur={handleBlur}
          />
          {touched.name && errors.name && (
            <small className={styles.error}>{errors.name}</small>
          )}

          <input
            className={styles.inputField}
            name="number"
            onChange={handleChange}
            value={values.number}
            placeholder="phone number"
          />
          {touched.number && errors.number && (
            <small className={styles.error}>{errors.number}</small>
          )}

          <input
            className={styles.inputField}
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="email *"
          />
          {touched.email && errors.email && (
            <small className={styles.error}>{errors.email}</small>
          )}

          <textarea
            rows={4}
            value={values.message}
            className={styles.inputField}
            placeholder="message *"
            onChange={handleChange}
            onBlur={handleBlur}
            name="message"
          />
          {touched.message && errors.message && (
            <small className={styles.error}>{errors.message}</small>
          )}

          <div className={styles.btnRow}>
            <Button className={styles.closeBtn} color="light" onClick={onClose}>
              close
            </Button>
            <Button
              type="submit"
              loading={loading}
              onClick={handleSubmit}
              color="green"
            >
              Send
            </Button>
          </div>
        </form>
        <div ref={tyRef} className={clsx(styles.tyContainer, styles.hidden)}>
          <span className={styles.tyTitle}>Thank You!</span>
          <span className={styles.tySubtitle}>
            We will get back to you as soon as possible.
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactUsModal;
