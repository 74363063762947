import React from "react";
import Section from "components/Section";
import Lottie from "react-lottie";
import animationData from "assets/lotties/not-found.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const NotFound = () => {
  return (
    <Section
      title={
        <>
          <Lottie
            options={defaultOptions}
            height={260}
            width={400}
            isClickToPauseDisabled
          />
          404
        </>
      }
      subTitle="Not Found"
      textAlignment="center"
    ></Section>
  );
};

export default NotFound;
