import React from "react";
import styles from "./styles.module.scss";
import SectionAnimation from "components/SectionAnimation";

interface HeroProps {
  id?: string;
}

const Hero = ({ id }: HeroProps) => {
  return (
    <SectionAnimation
      id={id}
      className={styles.hero}
      title="Be the best you can be."
      subTitle="Let us empower you with a lifestyle designed to bring the best out of you."
      textPlacement="bottom"
      textAlignment="center"
      animationDirection="down"
      animation="winner"
    />
  );
};

export default Hero;
