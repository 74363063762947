import React, { useEffect } from "react";
import styles from "./App.module.scss";
import { Switch, Route, useLocation } from "react-router-dom";
import Home from "pages/Home";
import Apply from "pages/Apply";
import Header from "components/Header";
import { config, useTransition } from "@react-spring/core";
import { animated } from "@react-spring/web";
import NotFound from "pages/NotFound";
import Footer from "components/Footer";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

export const routes = {
  home: "/",
  apply: "/apply",
};

const App = () => {
  const location = useLocation();

  const transitions = useTransition(location, {
    from: { opacity: 0.3 },
    enter: { opacity: 1 },
    // leave: { opacity: 0 },
    config: config.molasses,
  });

  useEffect(() => {
    const anchorParam = window.location.hash.replace("#", "");
    const element = document.getElementById(anchorParam);

    element?.scrollIntoView({
      // optional params
      behavior: "smooth",
      block: "start",
      inline: "center",
    });
  }, []);

  useEffect(() => {
    window.gtag("event", `page_${location.pathname}`, {
      event_category: "page-view",
    });
  }, [location]);

  return (
    <div className={styles.app}>
      <ScrollToTop />
      <Header />
      <main className={styles.main}>
        {transitions((style, location) => (
          <animated.div style={style}>
            <Switch location={location}>
              <Route exact path={routes.home} component={Home} />
              <Route exact path={routes.apply} component={Apply} />
              <Route component={NotFound} />
            </Switch>
          </animated.div>
        ))}
      </main>
      <Footer />
    </div>
  );
};

export default App;
