import React from "react";
// import styles from "./styles.module.scss";
import SectionAnimation from "components/SectionAnimation";

interface LifeCoachProps {
  id?: string;
}

const LifeCoach = ({ id }: LifeCoachProps) => {
  return (
    <SectionAnimation
      id={id}
      title="Your personal life coach."
      subTitle="We'll work with you to create a personalized program that sets both short- and long-term goals. This will help you declutter your mind, set priorities, and make better decisions- both big and small. We know that making even small changes can have a big impact on your overall happiness and productivity. Let us help you achieve your goals and reach your full potential."
      textPlacement="right"
      background="lightGrey"
      animation="personalTrainer"
    />
  );
};

export default LifeCoach;
