import React from "react";
import useWindowWidth from "hooks/useWindowWidth";
import Particles from "react-tsparticles";
import styles from "./styles.module.scss";

const ParticlesBackground = () => {
  const windowWidth = useWindowWidth(200);
  const particlesNumber = 300 + 0.025 * windowWidth;

  return (
    <Particles
      className={styles.particles}
      id="tsparticles"
      options={{
        pauseOnOutsideViewport: true,
        particles: {
          number: {
            value: particlesNumber,
            density: {
              enable: true,
              value_area: 2604.1872173865,
            },
          },
          color: {
            value: "#ffffff",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#ffffff",
            },
            polygon: {
              nb_sides: 5,
            },
          },
          opacity: {
            value: 0.5,
            random: true,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 2,
            random: true,
            anim: {
              enable: false,
              speed: 20,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: false,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: true,
            attract: {
              enable: false,
              rotateX: 3287.179199371425,
              rotateY: 4890.68124784529,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: false,
              mode: "grab",
            },
            onclick: {
              enable: false,
              mode: "bubble",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 194.8827049719449,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 133.98185966821214,
              size: 125.86174696104774,
              duration: 4.466061988940404,
              opacity: 0.3654050718223967,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      }}
    />
  );
};

export default React.memo(ParticlesBackground);
