import React from "react";
// import styles from "./styles.module.scss";
import Section from "components/Section";
import TechStack from "components/TechStack";

interface TechnologesProps {
  id?: string;
}

const Technologes = ({ id }: TechnologesProps) => {
  return (
    <Section
      id={id}
      title="A wide range of technologies."
      subTitle="We combine the latest technology trends to offer well-tailored applications that fit your requirements."
      animationDirection="up"
    >
      <TechStack />
    </Section>
  );
};

export default Technologes;
