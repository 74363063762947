import React from "react";

import styles from "./styles.module.scss";

interface DividerProps {
  inset?: "none" | "left" | "center" | "right";
}

const insetToStyleMap = {
  none: {},
  left: { width: "90%", marginLeft: "10%" },
  right: { width: "90%", marginRight: "10%" },
  center: { width: "80%" },
};

const Divider = ({ inset = "none" }: DividerProps) => {
  return <hr className={styles.divider} style={insetToStyleMap[inset]} />;
};

export default Divider;
