import React from "react";
import Section, { SectionProps } from "components/Section";
import styles from "./styles.module.scss";
import clsx from "clsx";
import envVars from "config/envVars";

export type Animation =
  | "none"
  | "balance"
  | "education"
  | "personalTrainer"
  | "thinkingOver"
  | "winner"
  | "time"
  | "seo"
  | "cloud"
  | "webDevelopment"
  | "celebrate"
  | "passion"
  | "inTime"
  | "details";

interface SectionAnimationProps extends Omit<SectionProps, "children"> {
  animation: Animation;
}

const SectionAnimation = ({
  onInView,
  animation,
  textPlacement,
  ...otherProps
}: SectionAnimationProps) => {
  const isAnimationTopOrBottom =
    textPlacement === "bottom" || textPlacement === "top";

  return (
    <Section textPlacement={textPlacement} {...otherProps}>
      <video
        autoPlay
        playsInline
        muted
        className={clsx({
          [styles.animationTopBottom]: isAnimationTopOrBottom,
        })}
        width="100%"
        loop
      >
        <source
          src={`${envVars.PUBLIC_URL}/animations/${animation}.webm`}
          type="video/webm"
        />
        <source
          src={`${envVars.PUBLIC_URL}/animations/${animation}.mov`}
          type='video/mp4; codecs="hvc1"'
        />
      </video>
    </Section>
  );
};

export default React.memo(SectionAnimation);
