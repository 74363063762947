import clsx from "clsx";
import React, { useState } from "react";
import styles from "./styles.module.scss";
import checkAnimation from "assets/lotties/animation-checked-done.json";
import chevronRight from "assets/chevron-right.svg";
import Lottie from "react-lottie";

const checkAnimationOptions = {
  loop: false,
  autoplay: false,
  animationData: checkAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
  initialSegment: [5, 21],
};

interface IconButtonProps {
  className?: string;
  color?: "light" | "dark" | "green";
  href?: string;
}
const IconButton = ({ className, href }: IconButtonProps) => {
  const [showAnimation, setShowAnimation] = useState(false);
  return (
    <a
      className={clsx(styles.iconButton, className)}
      onClick={() => {
        setShowAnimation((prev) => !prev);
      }}
      href={href}
    >
      <img src={chevronRight} alt="chevron-right" />
      <div
        className={clsx(styles.animation, {
          [styles.showAnimation]: showAnimation,
        })}
      >
        <Lottie
          options={checkAnimationOptions}
          height={200}
          width={200}
          isStopped={!showAnimation}
          isPaused={!showAnimation}
          isClickToPauseDisabled={true}
        />
      </div>
    </a>
  );
};

export default IconButton;
