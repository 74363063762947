import React from "react";
import Card from "./Card";
import {
  FirebaseLogo,
  GraphQLLogo,
  Html5Logo,
  ReactLogo,
  StorybookLogo,
  AzureLogo,
  GoogleCloudLogo,
  NodeJsLogo,
  GoogleAnalyticsLogo,
  JestLogo,
  Css3Logo,
  SassLogo,
  i18NextLogo,
  figmaLogo,
  zeplinLogo,
  githubActionsLogo,
  eslintLogo,
  javascriptLogo,
  prettierLogo,
  typescriptLogo,
  materialUILogo,
  webpackLogo,
  dockerLogo,
  adobeXdLogo,
  openAILogo
} from "assets/tech";
import styles from "./styles.module.scss";

const Row1 = (
  <>
    <Card label="React" imgSrc={ReactLogo} />
    <Card label="Storybook" imgSrc={StorybookLogo} />
    <Card label="GraphQL" imgSrc={GraphQLLogo} />
    <Card label="HTML5" imgSrc={Html5Logo} />
    <Card label="CSS3" imgSrc={Css3Logo} />
    <Card label="Firebase" imgSrc={FirebaseLogo} />
    <Card label="Azure" imgSrc={AzureLogo} />
    <Card label="Adobe Xd" imgSrc={adobeXdLogo} />
  </>
);

const Row2 = (
  <>
    <Card label="Google Cloud" imgSrc={GoogleCloudLogo} />
    <Card label="node.js" imgSrc={NodeJsLogo} />
    <Card label="Google Analytics" imgSrc={GoogleAnalyticsLogo} />
    <Card label="OpenAI" imgSrc={openAILogo} />
    <Card label="Jest" imgSrc={JestLogo} />
    <Card label="Sass" imgSrc={SassLogo} />
    <Card label="i18next" imgSrc={i18NextLogo} />
    <Card label="figma" imgSrc={figmaLogo} />
    <Card label="webpack" imgSrc={webpackLogo} />
  </>
);

const Row3 = (
  <>
    <Card label="zeplin" imgSrc={zeplinLogo} />
    <Card label="Github Actions" imgSrc={githubActionsLogo} />
    <Card label="ESLint" imgSrc={eslintLogo} />
    <Card label="JavaScript (ES11)" imgSrc={javascriptLogo} />
    <Card label="TypeScript" imgSrc={typescriptLogo} />
    <Card label="Prettier" imgSrc={prettierLogo} />
    <Card label="Material-UI" imgSrc={materialUILogo} />
    <Card label="Docker" imgSrc={dockerLogo} />
  </>
);

const TechStack = () => {
  return (
    <div className={styles.cards}>
      <div className={styles.slideshow}>
        <div className={styles.row}>
          {Row1}
          {Row1}
          {Row1}
        </div>
        <div className={styles.row}>
          {Row2}
          {Row2}
          {Row2}
        </div>
        <div className={styles.row}>
          {Row3}
          {Row3}
          {Row3}
        </div>
      </div>
    </div>
  );
};

export default TechStack;
