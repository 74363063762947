import React from "react";
import styles from "./styles.module.scss";
import Section from "components/Section";
import logo from "assets/pixelcloud.svg";
import ParticlesBackground from "components/ParticlesBackground";

interface IntroProps {
  id?: string;
}

const Intro = ({ id }: IntroProps) => {
  return (
    <Section
      className={styles.intro}
      subTitle="We're a web studio based in Malmö, Sweden, making the web a better place, one pixel at a time."
      textPlacement="bottom"
      textAlignment="center"
      animationDirection="down"
      id={id}
    >
      <ParticlesBackground />
      <img className={styles.logo} src={logo} alt="logo" />
    </Section>
  );
};

export default Intro;
