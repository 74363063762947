import clsx from "clsx";
import React from "react";

import styles from "./styles.module.scss";

interface HeaderLogoProps {
  color?: "light" | "dark";
}

const HeaderLogo = ({ color = "light" }: HeaderLogoProps) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 409.33 55.07"
        className={clsx(styles.logo, styles[color])}
      >
        <path
          className={styles.cloud}
          d="M84.3,32l-7-1.4l-2.2-7.2l-5.9-5.3l-8.1-2.4l-5.7,0.8L43.4,5.6L28.7,8.8l-6.2,6.7l-1.9,9.1L14.4,26l-7,16.5
	l8.9,10.5h31.5l0,0l0,0h30l8-4l2.5-10.2L84.3,32z M82.8,32.6L71.4,43.1c-0.1,0-0.2,0-0.3-0.1l-6.5-13.8l11.9,2.1l0,0L82.8,32.6z
	 M55.7,40.3C55.7,40.2,55.7,40.2,55.7,40.3c0-0.2,0-0.3-0.1-0.5l7.6-10.3c0.1,0,0.1,0,0.2,0l6.6,14c0,0.1-0.1,0.1-0.1,0.2L55.7,40.3
	z M62.4,28.8L54.9,39c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0.1L41.6,23.4c0.1-0.1,0.2-0.3,0.2-0.5L55,17.9l7.4,9.9
	c-0.1,0.2-0.1,0.3-0.1,0.5C62.3,28.4,62.3,28.6,62.4,28.8z M41.5,21.9c-0.1-0.1-0.1-0.1-0.2-0.2l2.6-14.5l10.7,9.6L41.5,21.9z
	 M40.2,21.5C40.1,21.5,40.1,21.5,40.2,21.5L30,9.4l12.8-2.7L40.2,21.5z M40.1,23.9c0.1,0,0.3,0.1,0.4,0.1c0,0,0,0,0.1,0l12.6,15.6
	l-24.8-4.4c0,0,0-0.1,0-0.1L40.1,23.9z M27.6,36.6c0.2-0.1,0.3-0.2,0.5-0.3l25.2,4.5c0,0.1,0.1,0.2,0.2,0.2l-6.3,11.2H26.7
	L27.6,36.6z M76.2,30.1l-11.3-2c0-0.3-0.1-0.5-0.3-0.7l4.6-8.2l5.1,4.6L76.2,30.1z M68.2,18.7L63.6,27c0,0,0,0,0,0
	c-0.1,0-0.2,0-0.3,0.1l-7.2-9.7l5-0.7L68.2,18.7z M23.4,15.9l5.5-6l10.4,12.4c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0.1,0.4L27.6,34.2
	c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0.1l-5.3-9.3L23.4,15.9z M15.1,26.8l5.7-1.3l5.3,9.2c-0.1,0.2-0.2,0.4-0.2,0.7
	c0,0,0,0.1,0,0.1l-17,6.1L15.1,26.8z M8.7,42.7l17.5-6.3c0.1,0,0.1,0.1,0.2,0.1l-0.9,15.6h-8.8L8.7,42.7z M54.5,41.5
	c0.2,0,0.4-0.1,0.6-0.2l14.8,3.5c0.2,0.5,0.6,0.8,1.2,0.8c0.1,0,0.2,0,0.3-0.1l5.4,6.6H48.4L54.5,41.5z M85,48.4l-7,3.5l-5.8-7
	c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2,0-0.3-0.1-0.4l11.7-10.7l3.4,5.8L85,48.4z"
        />
        <g>
          <path
            className={styles.text}
            d="M108.7,45.7V19.9h12.9c4.8,0,8.7,3.8,8.7,8.6c0,4.8-3.9,8.6-8.7,8.6h-7.1v8.6H108.7z M114.4,25v6.9h6.8
		c1.6,0,3.2-1.5,3.2-3.5c0-1.9-1.7-3.4-3.2-3.4H114.4z"
          />
          <path className={styles.text} d="M141.7,45.7H136V19.9h5.7V45.7z" />
          <path
            className={styles.text}
            d="M147.1,45.7l9.8-13.5l-8.5-12.3h6.4l5.3,8l5.3-8h6.4l-8.5,12.3l9.8,13.5h-6.9l-6.1-9l-6.1,9H147.1z"
          />
          <path
            className={styles.text}
            d="M178.5,45.7V19.9h17.7V25h-12v5.1h9.8v5.2h-9.8v5.2h12v5.2H178.5z"
          />
          <path
            className={styles.text}
            d="M202.9,45.7V19.9h5.7v20.7h12.2v5.2H202.9z"
          />
          <path
            className={styles.text}
            d="M239.8,46.3c-7.8,0-14.1-6-14.1-13.5c0-7.5,6.3-13.5,14.1-13.5c6.1,0,11.4,3.8,13.3,9.1h-6.3c-1.5-2.3-4.1-3.9-7-3.9
		c-4.6,0-8.3,3.7-8.3,8.3c0,4.6,3.7,8.3,8.3,8.3c2.9,0,5.5-1.5,7-3.8h6.3C251.1,42.6,245.9,46.3,239.8,46.3z"
          />
          <path
            className={styles.text}
            d="M259.1,45.7V19.9h5.7v20.7H277v5.2H259.1z"
          />
          <path
            className={styles.text}
            d="M281.9,32.8c0-7.5,6.3-13.5,14-13.5c7.7,0,14,6,14,13.5c0,7.5-6.3,13.5-14,13.5C288.1,46.3,281.9,40.3,281.9,32.8z
		 M295.9,24.5c-4.6,0-8.3,3.7-8.3,8.3c0,4.6,3.7,8.3,8.3,8.3s8.3-3.7,8.3-8.3C304.1,28.2,300.4,24.5,295.9,24.5z"
          />
          <path
            className={styles.text}
            d="M321.6,19.9v15.5c0,3.2,2.2,5.7,5.4,5.7c3.1,0,5.4-2.6,5.4-5.7V19.9h5.7v15.9c0,6-4.8,10.6-11.1,10.6
		c-6.3,0-11.1-4.5-11.1-10.6V19.9H321.6z"
          />
          <path
            className={styles.text}
            d="M355.4,45.7H345V19.9h10.4c8.1,0,13.7,5.5,13.7,12.9C369.1,40.3,363.9,45.7,355.4,45.7z M363.4,32.8c0-4.3-3.5-7.8-7.8-7.8
		h-4.9v15.5h4.9C359.9,40.6,363.4,37.1,363.4,32.8z"
          />
        </g>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 54"
        className={clsx(styles.onlyCloudLogo, styles[color])}
      >
        <path
          className={styles.cloud}
          d="M84.3,32l-7-1.4l-2.2-7.2l-5.9-5.3l-8.1-2.4l-5.7,0.8L43.4,5.6L28.7,8.8l-6.2,6.7l-1.9,9.1L14.4,26l-7,16.5
	l8.9,10.5h31.5l0,0l0,0h30l8-4l2.5-10.2L84.3,32z M82.8,32.6L71.4,43.1c-0.1,0-0.2,0-0.3-0.1l-6.5-13.8l11.9,2.1l0,0L82.8,32.6z
	 M55.7,40.3C55.7,40.2,55.7,40.2,55.7,40.3c0-0.2,0-0.3-0.1-0.5l7.6-10.3c0.1,0,0.1,0,0.2,0l6.6,14c0,0.1-0.1,0.1-0.1,0.2L55.7,40.3
	z M62.4,28.8L54.9,39c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0.1L41.6,23.4c0.1-0.1,0.2-0.3,0.2-0.5L55,17.9l7.4,9.9
	c-0.1,0.2-0.1,0.3-0.1,0.5C62.3,28.4,62.3,28.6,62.4,28.8z M41.5,21.9c-0.1-0.1-0.1-0.1-0.2-0.2l2.6-14.5l10.7,9.6L41.5,21.9z
	 M40.2,21.5C40.1,21.5,40.1,21.5,40.2,21.5L30,9.4l12.8-2.7L40.2,21.5z M40.1,23.9c0.1,0,0.3,0.1,0.4,0.1c0,0,0,0,0.1,0l12.6,15.6
	l-24.8-4.4c0,0,0-0.1,0-0.1L40.1,23.9z M27.6,36.6c0.2-0.1,0.3-0.2,0.5-0.3l25.2,4.5c0,0.1,0.1,0.2,0.2,0.2l-6.3,11.2H26.7
	L27.6,36.6z M76.2,30.1l-11.3-2c0-0.3-0.1-0.5-0.3-0.7l4.6-8.2l5.1,4.6L76.2,30.1z M68.2,18.7L63.6,27c0,0,0,0,0,0
	c-0.1,0-0.2,0-0.3,0.1l-7.2-9.7l5-0.7L68.2,18.7z M23.4,15.9l5.5-6l10.4,12.4c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0.1,0.4L27.6,34.2
	c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0.1l-5.3-9.3L23.4,15.9z M15.1,26.8l5.7-1.3l5.3,9.2c-0.1,0.2-0.2,0.4-0.2,0.7
	c0,0,0,0.1,0,0.1l-17,6.1L15.1,26.8z M8.7,42.7l17.5-6.3c0.1,0,0.1,0.1,0.2,0.1l-0.9,15.6h-8.8L8.7,42.7z M54.5,41.5
	c0.2,0,0.4-0.1,0.6-0.2l14.8,3.5c0.2,0.5,0.6,0.8,1.2,0.8c0.1,0,0.2,0,0.3-0.1l5.4,6.6H48.4L54.5,41.5z M85,48.4l-7,3.5l-5.8-7
	c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2,0-0.3-0.1-0.4l11.7-10.7l3.4,5.8L85,48.4z"
        />
      </svg>
    </>
  );
};

export default HeaderLogo;
