import React from "react";
import styles from "./styles.module.scss";

interface CardProps {
  label: string;
  imgSrc: string;
}

const Card = ({ label, imgSrc }: CardProps) => {
  return (
    <article className={styles.card}>
      <img className={styles.img} src={imgSrc} alt={label} />
      <h2 className={styles.title}>{label}</h2>
    </article>
  );
};

export default Card;
