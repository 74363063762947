import React from "react";
import styles from "./Footer.module.scss";
import { NavLink } from "react-router-dom";
import { routes } from "App";
import linkedInAnimation from "assets/lotties/linked-in.json";
import phoneAnimation from "assets/lotties/phone.json";
import LottieButton from "components/LottieButton";
import GaOnClickEvent from "components/GaOnClickEvent";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <nav className={styles.nav}>
        <div>
          <NavLink
            className={styles.navLink}
            activeClassName={styles.activeLink}
            to={routes.home}
            exact
          >
            About
          </NavLink>
          <NavLink
            className={styles.navLink}
            activeClassName={styles.activeLink}
            to={routes.apply}
          >
            Jobs
          </NavLink>
        </div>
        <div className={styles.right}>
          <GaOnClickEvent action="footer_phone" category="contact">
            <LottieButton
              animationData={phoneAnimation}
              height={25}
              width={25}
              href="tel:+46707488298"
            />
          </GaOnClickEvent>
          <GaOnClickEvent action="footer_linkedin" category="contact">
            <LottieButton
              animationData={linkedInAnimation}
              height={25}
              width={25}
              href="https://www.linkedin.com/company/pixelcloud-ab"
            />
          </GaOnClickEvent>
        </div>
        <div className={styles.headerBackground} />
      </nav>
    </footer>
  );
};

export default Footer;
