import React from "react";
import Intro from "./Intro";
import Time from "./Time";
import WebDevelopment from "./WebDevelopment";
// import styles from "./styles.module.scss";
import WhyUs from "./WhyUs";
import ContactUs from "./ContactUs";
import Technologes from "./Technologies";

const Home = () => {
  return (
    <>
      <Intro id="🏠" />
      <Time id="💻" />
      <Technologes id="📡" />
      <WebDevelopment id="🔎" />
      <WhyUs id="🤘" />
      <ContactUs id="📧" />
    </>
  );
};

export default Home;
