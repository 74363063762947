import React from "react";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { Animation } from "components/SectionAnimation";
import IconButton from "components/IconButton";
import GaOnClickEvent from "components/GaOnClickEvent";
import envVars from "config/envVars";

interface IconCardProps {
  className?: string;
  animation: Animation;
  title?: string;
  subTitle?: string;
  href?: string;
  analyticsEventName?: string;
}

const IconCard = ({
  title,
  subTitle,
  animation,
  className,
  href,
  analyticsEventName = "iconcard_not_set",
}: IconCardProps) => {
  return (
    <div className={clsx(styles.card, className)}>
      <div>
        {animation && (
          <div className={styles.img}>
            <video autoPlay={true} playsInline muted width="100%" loop>
              <source
                src={`${envVars.PUBLIC_URL}/animations/${animation}.webm`}
                type="video/webm"
              />
              <source
                src={`${envVars.PUBLIC_URL}/animations/${animation}.mov`}
                type='video/mp4; codecs="hvc1"'
              />
            </video>
          </div>
        )}
        {title && <p className={styles.title}>{title}</p>}
        {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
      </div>
      <div>
        <GaOnClickEvent action={analyticsEventName} category="contact">
          <IconButton className={styles.iconButton} href={href} />
        </GaOnClickEvent>
      </div>
    </div>
  );
};

export default IconCard;
