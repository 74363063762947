import React from "react";
import styles from "./styles.module.scss";
import IconCard from "components/IconCard";
import Section from "components/Section";

interface WhyUsProps {
  id?: string;
}

const WhyUs = ({ id }: WhyUsProps) => {
  return (
    <Section
      id={id}
      background="lightGrey"
      title="What we're about."
      subTitle="We bring the talent, tools, and passion to help you overcome your product challenges."
      animationDirection="up"
      textAlignment="center"
      className={styles.container}
    >
      <div className={styles.cardContainer}>
        <IconCard
          animation="passion"
          title="Web development"
          subTitle="We specialize in custom web development, so you can get a website that perfectly fits your needs."
          href="mailto:info@pixelcloud.se?subject=Let%27s%20make%20cool%20web%20applications%20together%21"
          analyticsEventName="what_we_do_web_development"
        />
        <IconCard
          animation="inTime"
          title="App development"
          subTitle="Our mobile application development services can help you create the perfect app for your business."
          href="mailto:info@pixelcloud.se?subject=Let%27s%20make%20cool%20apps%20together%21"
          analyticsEventName="what_we_do_app_development"
        />
        <IconCard
          animation="details"
          title="Branding & design"
          subTitle="Our branding and design services will help you create a strong visual identity that will make you stand out from the competition."
          href="mailto:info@pixelcloud.se?subject=Let%27s%20make%20cool%20design%20stuff%20together%21"
          analyticsEventName="what_we_do_design"
        />
      </div>
    </Section>
  );
};

export default WhyUs;
