import React, { memo, useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grow from "@mui/material/Grow";
import { TransitionProps } from "@mui/material/transitions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
let x = window.innerWidth / 2;
let y = window.innerHeight / 2;

interface ModalProps {
  children: React.ReactElement<any, any>;
  ButtonRef: React.RefObject<any>;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Grow ref={ref} style={{ transformOrigin: `${x}px ${y}px 0` }} {...props} />
  );
});

const Modal = ({ children, ButtonRef }: ModalProps) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const OpenModal = useCallback((e: any) => {
    if (e) {
      x = e.clientX;
      y = e.clientY;
    }
    setOpen(true);
  }, []);
  const closeModal = useCallback((e: any) => {
    setOpen(false);
  }, []);

  useEffect(() => {
    const currentRef = ButtonRef?.current;
    currentRef.addEventListener("click", OpenModal);

    return () => {
      currentRef.removeEventListener("click", OpenModal);
    };
  }, [ButtonRef, OpenModal]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={closeModal}
      aria-describedby="alert-dialog-slide-description"
      fullScreen={fullScreen}
      maxWidth="md"
      PaperProps={{
        style: { borderRadius: 15 },
      }}
    >
      <DialogContent sx={{ p: 2, backgroundColor: "#F5F5F5" }}>
        {React.cloneElement(children, { onClose: closeModal })}
      </DialogContent>
    </Dialog>
  );
};

export default memo(Modal);
