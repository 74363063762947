import React, { useRef, useState } from "react";
import styles from "./Header.module.scss";
import { NavLink } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { routes } from "App";
import clsx from "clsx";
import HeaderLogo from "./HeaderLogo";
import GaOnClickEvent from "components/GaOnClickEvent";
import Modal from "components/Modal";
import ContactUsModal from "components/ContactUsModal";

const Header = () => {
  const [hasScrolled, setHasScrolled] = useState(false);
  useScrollPosition(({ currPos }) => {
    setHasScrolled(currPos.y < 0);
  });
  const buttonRef = useRef(null);
  return (
    <header
      className={clsx(styles.header, {
        [styles.hasScrolled]: hasScrolled,
      })}
    >
      <Modal ButtonRef={buttonRef}>
        <ContactUsModal />
      </Modal>
      <nav className={styles.nav}>
        <NavLink to={routes.home} className={styles.logoContainer} exact>
          <HeaderLogo color={hasScrolled ? "dark" : "light"} />
        </NavLink>
        <div className={styles.links}>
          <NavLink
            className={styles.navLink}
            activeClassName={styles.activeLink}
            to={routes.home}
            exact
          >
            Home
          </NavLink>
          <NavLink
            className={styles.navLink}
            activeClassName={styles.activeLink}
            to={routes.apply}
          >
            Jobs
          </NavLink>
          <GaOnClickEvent action="header_email" category="contact">
            <a ref={buttonRef} className={styles.actionButton}>
              <span>Let's talk</span>
            </a>
          </GaOnClickEvent>
        </div>
        <div className={styles.headerBackground} />
      </nav>
    </header>
  );
};

export default Header;
