import React from "react";
// import styles from "./styles.module.scss";
import SectionAnimation from "components/SectionAnimation";

interface EducationProps {
  id?: string;
}

const Education = ({ id }: EducationProps) => {
  return (
    <SectionAnimation
      id={id}
      title={
        <>
          Get smarter on{" "}
          <span style={{ textDecoration: "underline", color: "#206e79" }}>
            your
          </span>{" "}
          own terms.
        </>
      }
      subTitle="
          At PixelCloud, we believe that finding the right learning method is
          key to success. We offer a variety of resources, including books,
          videos, workshops and exercises so that you can find the approach that
          works best for you. And, because we believe that everyone has the
          potential to be a leader, we also offer opportunities for you to share
          your knowledge with others. Our approach is simple: we provide the
          resources, support, and encouragement you need to succeed."
      textPlacement="left"
      background="darkGrey"
      animation="education"
    />
  );
};

export default Education;
