import clsx from "clsx";
import React, { ReactNode, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useSpring, animated, config } from "react-spring";
import { getSpringToConfig, Direction } from "utils/animations";
import styles from "./styles.module.scss";

interface PlacementProps {
  header: ReactNode;
  children: ReactNode;
}

const TextTop = ({ header, children }: PlacementProps) => (
  <>
    {header}
    {children}
  </>
);

const TextLeft = ({ header, children }: PlacementProps) => (
  <div className={clsx(styles.row, styles.textLeft)}>
    <div className={styles.marginRight}>{header}</div>
    <div>{children}</div>
  </div>
);

const TextBottom = ({ header, children }: PlacementProps) => (
  <div>
    {children}
    {header}
  </div>
);

const TextRight = ({ header, children }: PlacementProps) => (
  <div className={styles.row}>
    <div>{children}</div>
    <div className={styles.marginLeft}>{header}</div>
  </div>
);

const placementToComponentMap = {
  top: TextTop,
  right: TextRight,
  bottom: TextBottom,
  left: TextLeft,
};

const textAlignmentToStyleMap = {
  left: {
    textAlign: "left" as CanvasTextAlign,
    marginRight: "auto",
  },
  right: {
    textAlign: "right" as CanvasTextAlign,
    marginLeft: "auto",
  },
  center: {
    textAlign: "center" as CanvasTextAlign,
    marginLeft: "auto",
    marginRight: "auto",
  },
};

const backgroundToClassNameMap = {
  lightGrey: styles.lightGreyBg,
  darkGrey: styles.darkGreyBg,
  darkBlue: styles.darkBlueBg,
  gradientGreen: styles.gradientGreenBg,
  transparent: styles.transparentBg,
};

export interface SectionProps {
  children?: ReactNode;
  title?: string | ReactNode;
  subTitle?: string | ReactNode;
  background?: keyof typeof backgroundToClassNameMap;
  textPlacement?: "top" | "right" | "bottom" | "left";
  textAlignment?: "left" | "center" | "right";
  className?: string;
  animationDirection?: Direction;
  onInView?: (inView: boolean) => void;
  id?: string;
}

const Section = ({
  children,
  title,
  subTitle,
  textPlacement = "top",
  background = "transparent",
  textAlignment = "left",
  className,
  animationDirection = "up",
  onInView,
  id,
}: SectionProps) => {
  const { ref: sectionRef, inView: sectionNearInView } = useInView({
    threshold: 0,
    // move threshold 1000px to prefetch and start animation in advance
    rootMargin: "500px",
  });
  const { ref: anchorRef, inView: sectionInView } = useInView({
    threshold: 0,
  });
  const { ref: contentRef, inView: contentInView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  useEffect(() => {
    onInView?.(sectionNearInView);
  }, [sectionNearInView, onInView]);

  useEffect(() => {
    if (id && sectionInView) {
      window.history.replaceState("", "", `#${id}`);
    }
  }, [sectionInView, id]);

  const { from, to } = getSpringToConfig(animationDirection);

  const animationProps = useSpring({
    config: { ...config.slow, bounce: 0 },
    to: contentInView ? to : null,
    from,
    delay: 0,
  });

  const Component = placementToComponentMap[textPlacement];
  const backgroundClass = backgroundToClassNameMap[background];

  return (
    <section
      id={id}
      className={clsx(styles.container, backgroundClass, className)}
      ref={sectionRef}
    >
      <div ref={anchorRef}>
        <div className={styles.content} ref={contentRef}>
          <Component
            header={
              <animated.div
                className={styles.heading}
                style={{
                  ...textAlignmentToStyleMap[textAlignment],
                  ...animationProps,
                }}
              >
                {title && <h1 className={styles.h1}>{title}</h1>}
                {subTitle && (
                  <div className={styles.subHeading}>{subTitle}</div>
                )}
              </animated.div>
            }
            children={children}
          />
        </div>
      </div>
    </section>
  );
};

export default React.memo(Section);
