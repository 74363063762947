import clsx from "clsx";
import React, { useCallback, useState } from "react";
import Lottie, { LottieProps } from "react-lottie";

import styles from "./styles.module.scss";

const defaultOptions = {
  loop: true,
  autoplay: false,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface LottieButtonProps extends Omit<LottieProps, "options"> {
  className?: string;
  href?: string;
  animationData?: any;
}

const LottieButton = ({
  className,
  animationData,
  href,
  ...otherProps
}: LottieButtonProps) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsPlaying(true);
  }, [setIsPlaying]);

  const handleMouseLeave = useCallback(() => {
    setIsPlaying(false);
  }, [setIsPlaying]);

  return (
    <a
      className={clsx(styles.lottieButton, className)}
      href={href}
      target="_blank"
      rel="noreferrer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Lottie
        options={{
          ...defaultOptions,
          animationData,
        }}
        isClickToPauseDisabled
        isStopped={!isPlaying}
        {...otherProps}
      />
    </a>
  );
};

export default LottieButton;
