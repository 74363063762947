import React, { useCallback } from "react";

interface GaOnClickEventProps {
  action: string;
  category?: string;
  label?: string;
  value?: number;
  children?: React.ReactNode;
}

const GaOnClickEvent = ({
  children,
  action,
  category,
  label,
  value,
}: GaOnClickEventProps) => {
  const handleClick = useCallback(() => {
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }, [action, category, label, value]);

  return <span onClick={handleClick}>{children}</span>;
};

export default GaOnClickEvent;
