import React from "react";
import clsx from "clsx";
import styles from "./styles.module.scss";

interface CardProps {
  children?: React.ReactNode;
  figure?: React.ReactNode;
  figurePlacement?: "right" | "bottom" | "left";
  className?: string;
}

const Card = ({ children, figure, figurePlacement, className }: CardProps) => {
  return (
    <div className={clsx(styles.root, className)}>
      <div className={clsx(styles.left, styles.children)}>{children}</div>
      <div className={clsx(styles.right, styles.figure)}>{figure}</div>
    </div>
  );
};

export default Card;
