import Button from "components/Button";
import ContactUsModal from "components/ContactUsModal";
import GaOnClickEvent from "components/GaOnClickEvent";
import Modal from "components/Modal";
import SectionAnimation from "components/SectionAnimation";
import React, { useRef } from "react";
import styles from "./styles.module.scss";

interface SignMeUpProps {
  id?: string;
}

const SignMeUp = ({ id }: SignMeUpProps) => {
  const buttonRef = useRef<any>(null);

  return (
    <SectionAnimation
      id={id}
      title="Join the family."
      subTitle={
        <>
          <p>
            We’re always on the look for talented people who are passionate
            about building high-quality software and take pride in their work.
            If you're driven to find new and better solutions to challenges that
            others might think are already solved, we'd love to talk to you.
          </p>
          <Modal ButtonRef={buttonRef}>
            <ContactUsModal />
          </Modal>
          <div ref={buttonRef} className={styles.buttonContainer}>
            <GaOnClickEvent action="jobs_email" category="contact">
              <Button color="light">APPLY</Button>
            </GaOnClickEvent>
          </div>
        </>
      }
      textPlacement="bottom"
      textAlignment="center"
      animationDirection="up"
      animation="celebrate"
    />
  );
};

export default SignMeUp;
