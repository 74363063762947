import React from "react";
import Hero from "./Hero";
import Education from "./Education";
import PersonalDevelopment from "./PersonalDevelopment";
// import styles from "./styles.module.scss";
import SignMeUp from "./SignMeUp";
import LifeCoach from "./LifeCoach";
import Flexibility from "./Flexibility";

const Apply = () => {
  return (
    <>
      <Hero id="🏆" />
      <PersonalDevelopment id="🧠" />
      <Flexibility id="🧘" />
      <Education id="📚" />
      <LifeCoach id="💪" />
      <SignMeUp id="👪" />
    </>
  );
};

export default Apply;
