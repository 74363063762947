import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import React from "react";

import styles from "./styles.module.scss";

interface ButtonProps {
  className?: string;
  children?: React.ReactNode;
  href?: string;
  color?: "light" | "dark" | "green";
  onClick?: () => void;
  ref?: React.RefObject<any> | null;
  type?: string;
  loading?: boolean;
}

const Button = ({
  className,
  children,
  href,
  onClick,
  color = "dark",
  ref,
  type,
  loading = false,
}: ButtonProps) => {
  return (
    <a
      className={clsx(
        styles.button,
        styles[color],
        className,
        loading && styles.loading
      )}
      onClick={onClick}
      href={href}
      ref={ref}
      type={type ?? "button"}
    >
      {loading ? (
        <CircularProgress sx={{ color: "white" }} size={20} />
      ) : (
        children
      )}
    </a>
  );
};

export default Button;
