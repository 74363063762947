export type Direction = "none" | "down" | "up";

export const getSpringToConfig = (direction: Direction) => {
  //TODO better solutoin?
  const config = {
    from: {
      transform: "translateY(0)",
      opacity: 0,
    },
    to: {
      transform: "translateY(0)",
      opacity: 1,
    },
  };

  if (direction === "down") {
    config.from.transform = "translateY(-25px)";
  }
  if (direction === "up") {
    config.from.transform = "translateY(25px)";
  }
  if (direction === "none") {
    config.from.opacity = 1;
  }

  return config;
};
